import React from "react";
import {  useParams } from "react-router-dom";
import {
  Box, 
  Typography, 
  TextField, 
  Button
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function DisplaySecretLink() {
  let { id } = useParams();

  const linkUrl = `${window.location.protocol}//${window.location.host}/s/${id}`;

  return (
    <Box
      sx={{
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4
      }}
      justifyContent="center"
      component="form"
      noValidate
    >
      <Typography 
        component="h1"
        variant="h4"
        sx={{
          color: 'primary.contrastText',
          fontFamily: 'JetBrains Mono, monospace;',
          fontWeight: '700'
        }}
        align="center"
        >
        Here's Your One-Time Secret Message Link
      </Typography>
      <Typography 
        component="p"
        sx={{
          color: 'primary.contrastText',
          fontFamily: 'JetBrains Mono, monospace;',
          mt: 3,
          fontStyle: 'italic'
        }}
        align="center"
        >
        This link will expire after 10 minutes
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="secret"
        name="secret"
        autoFocus
        multiline
        rows={3}
        inputProps={{
          readOnly: true
        }}
        sx={{
          backgroundColor: 'primary.contrastText',
          borderRadius: '12px',
          borderColor: 'secondary.dark',
          fontFamily: 'JetBrains Mono, monospace;',
          mt: 6
        }}
        value={linkUrl}
      />
      <Button
        align="center"
        variant="contained"
        sx={{ 
          mt: 3, 
          mb: 2,
          backgroundColor: 'secondary.dark',
          color: 'primary.contrastText',
          fontFamily: 'JetBrains Mono, monospace;',
          fontWeight: 'bold',
          ':hover': {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText'
          }
        }}
        onClick={() => {
          navigator.clipboard.writeText(linkUrl)
        }}
      >
        Copy To Clipboard
        <ContentCopyIcon 
          sx={{
            marginLeft: '5px'
          }}
        />
      </Button>
    </Box>
  );
}

export default DisplaySecretLink;