import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import {
  CircularProgress, 
  Box, 
  Typography, 
  TextField, 
  Button
} from '@mui/material';

function Home() {
  let [isProcessing, setIsProcessing] = useState(false);
  let [secretObject, setSecretObject] = useState(false);
  let [processingError, setProcessingError] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    
    setIsProcessing(true);

    axios
      .post('/api/put', {secret: data.get('secret')})
      .then((response) => {
        setSecretObject(response.data);
        setIsProcessing(false);
      })
      .catch((error) => {
        setProcessingError(error)
        setIsProcessing(false);
      })
  }

  if(isProcessing) {
    return (
      <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 4
          }}
        >
        <CircularProgress
          sx={{
            color:'secondary.dark'
          }}
        />
      </Box>);
  }

  if(processingError) {
    return (
      <div>
        Processing error.
        {processingError}
      </div>
    )
  }

  if(secretObject) {
    return (
      <Redirect to={`/show/${secretObject.id}`}/>
    )
  }

  return (
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 4
          }}
          justifyContent="center"
          component="form" onSubmit={onSubmit} noValidate
        >
          <Typography 
            component="h1"
            variant="h3"
            sx={{
              color: 'primary.contrastText',
              fontFamily: 'JetBrains Mono, monospace;',
              fontWeight: '700'
            }}
            align="center"
            >
            Secret Message
          </Typography>
          <Typography 
            component="p"
            sx={{
              color: 'primary.contrastText',
              fontFamily: 'JetBrains Mono, monospace;',
              mt: 3,
              fontStyle: 'italic'
            }}
            align="center"
            >
            Enter some text to send as a one-time read only message.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="secret"
            name="secret"
            placeholder="Type your secret here & hit submit to get your link..."
            autoFocus
            multiline
            rows={3}
            sx={{
              backgroundColor: 'primary.contrastText',
              borderRadius: '12px',
              borderColor: 'secondary.dark',
              fontFamily: 'JetBrains Mono, monospace;',
              mt: 6
            }}
          />
          <Button
            type="submit"
            align="center"
            variant="contained"
            sx={{ 
              mt: 3, 
              mb: 2,
              backgroundColor: 'secondary.dark',
              color: 'primary.contrastText',
              fontFamily: 'JetBrains Mono, monospace;',
              fontWeight: 'bold',
              ':hover': {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText'
              }
            }}
          >
            Submit
          </Button>
        </Box>  
  );
}

export default Home;