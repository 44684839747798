import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './components/home/Home';
import ReadSecret from './components/readSecret/ReadSecret';
import DisplaySecretLink from './components/displaySecretLink/DisplaySecretLink';
import {
  Container,
  CssBaseline,
  ThemeProvider,
  GlobalStyles,
  Grid,
  Box
} from '@mui/material';
import theme from './util/theme';
import './App.scss';

export default function BasicExample() {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline 
        />
        <GlobalStyles
          styles={{
            body: { backgroundImage: "radial-gradient(circle, #286359, #42786c, #5b8d7f, #74a393, #8ebaa8);" }
          }}
        />
        <Router>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container={true}
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '100vh' }}
              flexGrow={1}
              columns={1}
            >
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/show/:id">
                  <DisplaySecretLink />
                </Route>
                <Route path="/s/:id">
                  <ReadSecret />
                </Route>
              </Switch>
            </Grid>
          </Box>
        </Router>
      </ThemeProvider>
    
  );
}
