import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      light: '#b9cdca',
      main: '#8ebaa8',
      dark: '#286359',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f1dccc',
      main: '#fdab89',
      dark: '#e28250',
      contrastText: '#392214',
    },
  },
});