import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  CircularProgress, 
  Box, 
  Typography, 
  TextField, 
  Button,
  Link
} from '@mui/material';

function ReadSecret() {

  let { id } = useParams();

  let [isLoading, setIsLoading] = useState(true);
  let [isError, setIsError] = useState(false);
  let [secretResponse, setSecretResponse] = useState({});

  useEffect(() => {
    console.log('use effect');
    axios
      .get(`/api/get/${id}`)
      .then((response) => {
        console.log(response);
        setSecretResponse(response.data);
        setIsLoading(false);
      })
      .catch((error => {
        console.log(error);
        setIsError(error);
        setIsLoading(false);
      }))
  }, []);

  if(isLoading) {
    return (
    <Box
      sx={{
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4
      }}
    >
    <CircularProgress
      sx={{
        color:'secondary.dark'
      }}
    />
  </Box>);
  }

  if(isError) {
    return (
      <div>
        {JSON.stringify(isError)}
      </div>
    )
  }

  return (
    <Box
      sx={{
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4
      }}
      justifyContent="center"
      component="form"
      noValidate
    >
    <Typography 
        component="h1"
        variant="h4"
        sx={{
          color: 'primary.contrastText',
          fontFamily: 'JetBrains Mono, monospace;',
          fontWeight: '700'
        }}
        align="center"
        >
        {secretResponse.description}
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="secret"
        name="secret"
        placeholder="Type your secret here & hit submit to get your link..."
        autoFocus
        multiline
        inputProps={{
          readOnly: true
        }}
        sx={{
          backgroundColor: 'primary.contrastText',
          borderRadius: '12px',
          borderColor: 'secondary.dark',
          fontFamily: 'JetBrains Mono, monospace;',
          mt: 6
        }}
        value={secretResponse.message}
      />
      {
        secretResponse.secretFound ?

        <Button
          align="center"
          variant="contained"
          sx={{ 
            mt: 3, 
            mb: 2,
            backgroundColor: 'secondary.dark',
            color: 'primary.contrastText',
            fontFamily: 'JetBrains Mono, monospace;',
            fontWeight: 'bold',
            ':hover': {
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText'
            }
          }}
          onClick={() => {navigator.clipboard.writeText(secretResponse.message)}}
        >
          Copy To Clipboard
        </Button>
        :
        ''
      }
      
      <Button
        type="submit"
        align="center"
        variant="contained"
        sx={{ 
          mt: 3, 
          mb: 2,
          backgroundColor: 'secondary.dark',
          color: 'primary.contrastText',
          fontFamily: 'JetBrains Mono, monospace;',
          fontWeight: 'bold',
          ':hover': {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText'
          }
        }}
      >
        <Link 
          href="/"
          sx={{ 
            color: 'primary.contrastText',
            fontFamily: 'JetBrains Mono, monospace;',
            fontWeight: 'bold',
            ':hover': {
              color: 'secondary.contrastText'
            }
          }}
        >
            Send a new secret
        </Link>
      </Button>
    </Box>
  );
}

export default ReadSecret;